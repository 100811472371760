import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/createStore";
import {LicenseInfo} from '@mui/x-license-pro';

// Redux + Firebase
import {Provider} from 'react-redux'

// import i18n
import './i18n';
import {ClientDBProvider} from "./store/clientDB/ClientDBContext";

LicenseInfo.setLicenseKey('869f54f1d442341f4722c1af57d4e688Tz03NDM2NSxFPTE3MjU4ODU3MjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <ClientDBProvider>
            <App/>
        </ClientDBProvider>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//check store
if (window.Cypress) {
    window.store = store;
}